<template>
  <div class="dialog">
    <el-dialog
      :title="typeFlag == 'add' ? '添加' : '修改'"
      :visible.sync="dialogVisible"
      width="700px"
      :before-close="close"
      :close-on-click-modal="false"
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="名称" prop="name">
          <el-input size="small" v-model="ruleForm.name"></el-input>
        </el-form-item>
        <el-form-item label="图片" prop="img_url">
          <Qiniu
            :isCropper="true"
            :width="525"
            :height="420"
            :imgKey="ruleForm.img_url"
            @uploadSuccess="uploadSuccess"
          ></Qiniu>
        </el-form-item>
        <el-form-item label="从属分类" prop="cate_id">
          <el-select
            size="small"
            v-model="ruleForm.cate_id"
            placeholder="请选择"
            @change="changeCate"
          >
            <el-option
              v-for="item in catesList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="包含标签" prop="tag_ids">
          <el-checkbox-group v-model="ruleForm.tag_ids" size="mini">
            <el-checkbox-button
              v-for="item in tags"
              :label="item.id"
              :key="item.id"
              >{{ item.tag_name }}</el-checkbox-button
            >
          </el-checkbox-group>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm('ruleForm')"
            >确定</el-button
          >
          <el-button @click="close()">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import { allCate } from "@/api/base";
import { addInterest, tagList } from "@/api/classTag.js";
import Qiniu from "@/components/qiniu.vue";
export default {
  name: "ClassAdd",
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    typeFlag: {
      // 判断是新建 编辑 详情
      type: String,
      default: "add",
    },
    row: {
      // 判断是新建 编辑 详情
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      catesList: [],
      ruleForm: {
        id: "",
        cate_id: "", //分类ID
        name: "", //	兴趣名
        img_url: "", //兴趣图片的URL
        tag_ids: [], //标签ID
      },
      rules: {
        name: [{ required: true, message: "请输入分类名称", trigger: "blur" }],
        cate_id: [{ required: true, message: "请选择分类", trigger: "change" }],
        img_url: [{ required: true, message: "上传图片", trigger: "blur" }],
        tag_ids: [{ required: true, message: "请选择标签", trigger: "change" }],
      },
      tags: [],
    };
  },
  components: {
    Qiniu,
  },
  watch: {
    dialogVisible: {
      handler() {
        if (this.dialogVisible) {
          this.ruleForm.id = this.row.id || "";
          this.ruleForm.name = this.row.name || "";
          this.ruleForm.cate_id = this.row.cate_id || "";
          this.ruleForm.img_url = this.row.img_url || "";
          if (this.row.tag_ids) {
            this.ruleForm.tag_ids = this.row.tag_ids.split(",") || [];
          }
          let arry = [];
          this.ruleForm.tag_ids.forEach((item) => {
            arry.push(item * 1);
          });
          this.ruleForm.tag_ids = arry;
          if (this.ruleForm.cate_id) {
            console.log(this.ruleForm.cate_id);
            this.getTags();
          }
        }
        this.$nextTick(() => {
          this.$refs.ruleForm.clearValidate();
        });
      },
      immediate: true,
    },
  },
  methods: {
    changeCate() {
      this.ruleForm.tag_ids = [];
      this.getTags();
    },
    // 获取列表
    getTags() {
      this.loading = true;
      const cate_id = this.ruleForm.cate_id;
      tagList({
        limit: 1000,
        page: 1,
        // cate_id: this.ruleForm.cate_id ? this.ruleForm.cate_id : null,
      })
        .then((res) => {
          let data = JSON.parse(JSON.stringify(res.data.data));
          // 把相同分类的传前边
          let obj = {};
          data.forEach((item) => {
            if (obj[item.cate_id]) {
              obj[item.cate_id].push(item);
            } else {
              obj[item.cate_id] = [];

              obj[item.cate_id].push(item);
            }
          });
          let arry = obj[cate_id] || [];
          delete obj[cate_id];
          Object.keys(obj).forEach((item) => {
            arry = arry.concat(obj[item]);
          });
          this.tags = arry;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
    // 获取分类
    getallCate() {
      if (this.catesList.length > 0) return;
      allCate()
        .then((res) => {
          this.catesList = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 确定
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.save();
        }
      });
    },
    save() {
      let body = JSON.parse(JSON.stringify(this.ruleForm));
      this.typeFlag == "edit" ? (body.id = this.row.id) : "";
      body.tag_ids = body.tag_ids.toString();
      addInterest(body)
        .then((res) => {
          if (res.code == 200) {
            this.close();
            this.$emit("getList");
          }
        })
        .catch((err) => {
          console.log(err, 222);
        });
    },
    // 关闭
    close() {
      this.ruleForm = {
        id: "",
        cate_id: "", //分类ID
        name: "", //	兴趣名
        img_url: "", //兴趣图片的URL
        tag_ids: [], //标签ID
      };
      this.tags = [];
      this.$emit("close");
    },
    // 选择图片成功
    uploadSuccess(e) {
      this.ruleForm.img_url = this.$constant.qnUrl + "/" + e.key;
    },
  },
};
</script>

<style lang="less" >
.dialog {
  .el-checkbox-button .el-checkbox-button__inner {
    border-left: 1px solid #dcdfe6;
    /* border-radius: 4px 0 0 4px; */
    border-radius: 4px;
  }
  .el-checkbox-button.is-checked .el-checkbox-button__inner {
    border-left-color: #409eff;
  }
  .el-checkbox-button {
    margin-right: 10px;
  }
}
</style>