<template>
  <div class="wrapper" v-loading="loading">
    <div class="top_box">
      <div class="search_box">
        <el-form :inline="true" @submit.native.prevent>
          <el-form-item label="名称">
            <el-input
              v-model="search_name"
              size="small"
              placeholder="名称"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button size="small" type="primary" @click="search()"
              >搜索</el-button
            >
            <el-button size="small" type="primary" @click="reSearch()"
              >重置</el-button
            >
          </el-form-item>
        </el-form>
      </div>
      <div class="top-btn" ref="topBtn">
        <div>
          <el-button size="small" type="primary" @click="add()"
            >添加兴趣</el-button
          >
        </div>
      </div>
    </div>

    <el-table
      :data="list"
      :max-height="tableHeight"
      :header-cell-style="{ background: '#f5f7fa' }"
      style="width: 100%"
    >
      <el-table-column prop="created_at" label="添加时间"> </el-table-column>
      <el-table-column prop="img_url" label="兴趣图片">
        <template slot-scope="scope">
          <img
            v-if="scope.row.img_url"
            :src="scope.row.img_url"
            style="width: 100px"
          />
        </template>
      </el-table-column>
      <el-table-column prop="name" label="名称"></el-table-column>
      <el-table-column prop="category.name" label="从属分类"> </el-table-column>
      <el-table-column prop="tags" label="包含标签">
        <template slot-scope="scope">
          {{ parseTags(scope.row.tags) }}
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-link
            type="primary"
            @click="edit(scope.row)"
            style="margin-right: 10px"
            >修改</el-link
          >
          <el-link type="primary" @click="interestEnable(scope.row)">
            {{ scope.row.status == 0 ? "禁用" : "启用" }}
          </el-link>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      v-if="total > 0"
      style="padding-top: 15px; text-align: center"
      @current-change="handleCurrentChange"
      :current-page.sync="currentPage"
      :page-size="limit"
      layout="prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>

    <ificationAdd
      ref="add"
      :dialogVisible="dialogVisible"
      :typeFlag="typeFlag"
      :row="row"
      @getList="getList"
      @close="close"
    />
  </div>
</template>
<script>
import ificationAdd from "./component/ificationAdd.vue";

import { interest, interestEnable } from "@/api/classTag.js";

export default {
  components: {
    ificationAdd,
  },
  data() {
    return {
      loading: true,
      tableHeight: this.$constant.maxHeight,
      // 列表
      total: 0,
      limit: 20,
      currentPage: 1,
      list: [],
      // 弹出框
      id: 0,
      dialogVisible: false,

      typeFlag: "add",
      row: {},
      search_name: "",
      active_name: "",
    };
  },
  mounted() {
    this.getList();
    this.tableHeight = this.$common.setHeight([this.$refs.topBtn], 130);
    window.onresize = this.$common.debounce(() => {
      this.tableHeight = this.$common.setHeight([this.$refs.topBtn], 130);
    }, 1000);
  },
  methods: {
    interestEnable(item) {
      this.$confirm(`确定${item.status == 0 ? "禁用" : "启用"}吗`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          interestEnable({ id: item.id })
            .then((res) => {
              if (res.code == 200) {
                this.$message({
                  message: "成功",
                  type: "success",
                });
                this.currentPage = 1;
                this.getList();
              }
            })
            .catch((err) => {});
        })
        .catch(() => {});
    },
    parseTags(e) {
      let Array = [];
      e.forEach((item) => {
        Array.push(item.name);
      });
      return Array.toString();
    },
    // 修改
    edit(row) {
      this.$refs.add.getallCate();
      this.typeFlag = "edit";
      this.row = row;
      this.dialogVisible = true;
    },

    // 添加
    add() {
      this.$refs.add.getallCate();
      this.typeFlag = "add";
      this.row = {};
      this.dialogVisible = true;
    },

    onSubmit() {
      let title, content;
      if (this.opera == "edit_auth") {
        content = "确定要修改此人的权限吗？";
        title = "修改权限";
      } else {
        content = "确定要修改此人的备注吗？";
        title = "修改备注";
      }
      this.$alert(content, title, {
        confirmButtonText: "确定",
        callback: (action) => {
          if (action == "confirm") {
            // 调用踢出方法
            this.opera == "edit_auth" ? this.editAuth() : this.editRemark();
          }
        },
      });
    },

    close() {
      this.dialogVisible = false;
      this.opera = "";
      this.id = "";
      this.formInline = {
        auth: "",
        region: "",
      };
    },
    search() {
      this.active_name = this.search_name;
      this.currentPage = 1;
      this.getList();
    },
    reSearch() {
      this.active_name = this.search_name = "";
      this.currentPage = 1;
      this.getList();
    },
    // 获取列表
    getList() {
      this.loading = true;
      let data = {
        limit: this.limit,
        page: this.currentPage,
        name: this.active_name,
      };
      interest(data)
        .then((res) => {
          this.total = res.data.total;
          this.list = res.data.data;
          this.loading = false;
        })
        .catch((err) => {
          console.log("接口错误", err);
        });
    },

    // 页码修改
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getList();
    },
  },
};
</script>
<style lang="less" scoped>
.top_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
